export const NavList =[

    {
        title: 'Home',
        path: '/',
        cName: 'nav-link'
    },

    {
        title: 'Brands',
        path: '/brands',
        cName: 'nav-link'
    },
    {
        title: 'Services',
        path: '/services',
        cName: 'nav-link'
    },
    {
        title: 'Partners',
        path: '/partners',
        cName: 'nav-link'
    },
    {
        title: 'Lets Ride',
        path: '/wheretoride',
        cName: 'nav-link'
    }


    
]


