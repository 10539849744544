export const ridingResources = [
    {
        name: "Out Spoken' Wheelman",
        link: "https://www.outspokinwheelmen.com/content.aspx?page_id=31&club_id=649605&action=login&sl=" 

    },
    {
        name: "CycWard Bike Club",
        link: "https://www.facebook.com/groups/2391868074230503" 

    },
    {
        name: "Crew Racing and Rehab",
        link: "https://www.crewracing.org/"
    },
    {
        name: "Rustbelt Trail Revival Trail Coalition",
        link: "https://www.facebook.com/rustbeltrevivaltrailcoalition"
    }



]


